<template>
  <div class="cinema-info-page">
    <div class="info-section">
      <div class="section-header">
        <van-icon name="shop-o" size="18" />
        <span>影院基本信息</span>
      </div>
      
      <div class="info-grid">
        <div class="info-item">
          <div class="info-label">影院编码</div>
          <div class="info-value">{{ cinema.CinemaCode }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">影院名称</div>
          <div class="info-value">{{ cinema.CinemaName }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">所属院线</div>
          <div class="info-value">{{ cinema.CinemaChain }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">行政区划</div>
          <div class="info-value">{{ cinema.Zoning }}</div>
        </div>
        <div class="info-item full-width">
          <div class="info-label">影院地址</div>
          <div class="info-value">{{ cinema.Address }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">开业时间</div>
          <div class="info-value">{{ cinema.OpenTime }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">影院面积</div>
          <div class="info-value">{{ cinema.Area || '-' }} m²</div>
        </div>
        <div class="info-item">
          <div class="info-label">影院厅数</div>
          <div class="info-value">{{ cinema.ScreenCount }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">影院座位数</div>
          <div class="info-value">{{ cinema.SeatCount }}</div>
        </div>
      </div>
    </div>

    <div class="info-section">
      <div class="section-header">
        <van-icon name="manager-o" size="18" />
        <span>管理人员信息</span>
      </div>
      
      <div class="info-grid">
        <div class="info-item">
          <div class="info-label">影院经理</div>
          <div class="info-value">{{ cinema.ManagerName }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">联系方式</div>
          <div class="info-value">{{ cinema.ManagerPhone }}</div>
        </div>
      </div>
    </div>

    <div class="info-section">
      <div class="section-header">
        <van-icon name="certificate" size="18" />
        <span>企业信息</span>
      </div>
      
      <div class="info-grid">
        <div class="info-item full-width">
          <div class="info-label">企业名称</div>
          <div class="info-value">{{ cinema.CompanyName }}</div>
        </div>
        <div class="info-item full-width">
          <div class="info-label">社会统一信用代码</div>
          <div class="info-value">{{ cinema.CompanyCode }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">企业法人</div>
          <div class="info-value">{{ cinema.LegalName }}</div>
        </div>
        <div class="info-item">
          <div class="info-label">法人电话</div>
          <div class="info-value">{{ cinema.LegalPhone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.cinema-info-page {
  padding: 16px;
  background: #f5f7fa;
  min-height: 100vh;
}

.info-section {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  .section-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    
    .van-icon {
      color: #1989fa;
    }
    
    span {
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
  }
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  .info-item {
    &.full-width {
      grid-column: span 2;
    }

    .info-label {
      font-size: 13px;
      color: #666;
      margin-bottom: 4px;
    }

    .info-value {
      font-size: 15px;
      color: #333;
      font-weight: 500;
    }
  }
}
</style>

<script>
import { zjApi as api } from '@/api/index'

export default {
  name: 'CinemaInfo',
  data() {
    return {
      cinemaCode: '',
      cinema: {},
    }
  },
  mounted() {
    this.cinemaCode = this.$route.query.code
    if (this.cinemaCode) this.getCinemaInfo()
  },
  methods: {
    getCinemaInfo() {
      const mobile = this.$store.state.userInfo.mobile
      const ps = `{"UserCode":"${mobile}","CmdIndex":"101003","cmdArgs":[${this.cinemaCode}]}`
      api.getPs({ ps }).then((r) => {
        const { Success, data } = r
        if (Success) {
          this.cinema = data
        }
      }).catch(console.error)
    },
  },
}
</script>
